var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products category-leaflet"},[_c('Breadcrumb',{attrs:{"items":_vm.breadcrumbs}}),_c('category-block',{staticClass:"category-block",attrs:{"target":_vm.category,"position":"position1"}}),_c('CategoryTitle',{key:_vm.category.categoryId,attrs:{"category":_vm.category}}),_c('v-container',[_c('div',{staticClass:"slider sticky-slider"},[_c('swiper',{ref:"swiperRef",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.category.children),function(subCategory){return _c('swiper-slide',{key:subCategory.categoryId},[_c('v-card',{staticClass:"category-card px-4 rounded-pill d-flex flex-row align-center justify-left",attrs:{"outlined":"","height":"50px","to":{
              name: 'Category',
              params: {
                pathMatch: _vm.category.slug
              },
              query: { categoryId: subCategory.categoryId }
            }}},[(
                _vm.$ebsn.meta(
                  subCategory,
                  'category_info.ICON',
                  _vm.$ebsn.meta(subCategory, 'category_info.THUMB')
                )
              )?_c('v-img',{staticClass:"center-img ml-1",attrs:{"contain":"","src":_vm.$ebsn.meta(
                  subCategory,
                  'category_info.ICON',
                  _vm.$ebsn.meta(subCategory, 'category_info.THUMB')
                ),"width":"20","height":"20","alt":subCategory.name}}):_vm._e(),_c('v-card-text',[_vm._v(" "+_vm._s(subCategory.name)+" ")])],1)],1)}),1),_c('swiper-paginator',{attrs:{"showBullets":true,"paginationClass":"category-list","length":_vm.category.children.length}}),_c('v-divider',{staticClass:"my-4"})],1),(!_vm.categoryId)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("categoryLeaflet.allProducts")))]),_c('ProductListGrid',{staticClass:"mt-5",attrs:{"hidePromoFilter":true,"parentCategoryId":_vm.category.categoryId,"infiniteScroll":false,"hideFilterString":"Categorie,Main Term","pageSize":_vm.$ebsn.meta(_vm.category, 'template_model.PAGE_SIZE'),"virtualScrollViewport":_vm.$vuetify.breakpoint.xsOnly,"virtualPageSize":_vm.$vuetify.breakpoint.xsOnly ? 4 : 1},on:{"productsCount":_vm.updateCount}})],1):_c('div',[_c('h3',[_c('v-btn',{staticClass:"mt-n2",attrs:{"icon":"","elevation":"0"},on:{"click":function($event){_vm.categoryId = null}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("$chevronLeft")])],1),_vm._v(" "+_vm._s(_vm.selectedCategory.name)+" ")],1),_c('ProductListGrid',{key:_vm.categoryId,attrs:{"parentCategoryId":_vm.category.categoryId,"categoryId":_vm.categoryId,"infiniteScroll":false,"hidePromoFilter":true,"hideFilterString":"Categorie,Main Term","pageSize":_vm.$ebsn.meta(_vm.category, 'template_model.PAGE_SIZE'),"virtualScrollViewport":_vm.$vuetify.breakpoint.xsOnly,"virtualPageSize":_vm.$vuetify.breakpoint.xsOnly ? 4 : 1},on:{"productsCount":_vm.updateCount}})],1)]),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"position2"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }