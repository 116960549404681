<template>
  <div
    v-if="category"
    class="category-title"
    :class="{
      'w-100 d-flex flex-column justify-center text-center': centered,
      'd-flex flex-column flex-md-row': showHeaderImg,
      'd-none': $ebsn.meta(
        category,
        'metaData.category_info.HIDE_TITLE',
        false
      ),
      container: addContainer
    }"
  >
    <ebsn-style :target="category" path="category_advanced.CSS_STYLE" />
    <template>
      <v-img
        v-if="showHeaderImg && headerImage"
        :src="headerImage"
        :height="height"
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'"
        class="mr-sm-5"
        @load="loaded = true"
        :alt="category.name"
      ></v-img>
      <div
        class="d-flex align-center flex-row flex-wrap"
        :class="{ 'justify-center': centered }"
      >
        <v-btn
          v-if="selectedCategory"
          icon
          :to="{ name: 'Category', params: { pathMatch: category.slug } }"
          exact
          ><v-icon>$prev</v-icon></v-btn
        >
        <ebsn-meta
          :target="selectedCategory ? selectedCategory : category"
          path="category_info.TITLE"
          tag="h1"
          :default-value="
            selectedCategory ? selectedCategory.name : category.name
          "
          :style="`color: ${$ebsn.meta(category, 'category_info.COLOR')}`"
        ></ebsn-meta>
        <span class="ml-2 mt-3" v-if="count > 0">({{ count }} prodotti)</span>
      </div>

      <ebsn-meta
        class="description"
        :target="category"
        path="category_info.DESCRIPTION"
        tag="div"
        @click="clicked"
      ></ebsn-meta>
    </template>
  </div>
</template>
<style lang="scss">
.category-title {
  p > a {
    color: var(--v-default-base) !important;
  }
  .description {
    img {
      max-width: 100%;
    }
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import get from "lodash/get";
import has from "lodash/has";

export default {
  name: "CategoryTitle",
  props: {
    count: { type: Number, required: false },
    category: { type: Object, required: true },
    selectedCategory: { type: Object, required: false },
    height: { type: String },
    centered: { type: Boolean, default: false },
    showHeaderImg: { type: Boolean, default: false },
    addContainer: {
      type: Boolean,
      default: true
    }
  },
  mixins: [clickHandler],
  data: function() {
    return { loaded: false };
  },
  computed: {
    titleClasses() {
      let setBold = !has(
        this.selectedCategory || this.category,
        "metaData.category_info.TITLE"
      );
      return {
        "font-weight-semibold": setBold
      };
    },
    headerImage() {
      let prop =
        "metaData.category_info." +
        (!this.$vuetify.breakpoint.smAndDown
          ? "HEADER_IMAGE"
          : "HEADER_IMAGE_MOBILE");
      return get(
        this.category,
        prop,
        get(this.category, "metaData.category_info.HEADER_IMAGE")
      );
    },
    hideTitle() {
      return get(this.category, "metaData.category_info.HIDE_TITLE", false);
    }
  }
};
</script>
